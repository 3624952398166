import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Poster = ({ data, location }) => (
  <Layout>
    <SEO 
      pagetitle="ポスター"
      pagedesc="ポスターは１枚７日間から広告展開出来る紙媒体の広告です。基本はＢ１縦サイズですが、Ｂ１サイズ枠を２つ使ってＢ０横サイズの展開も可能です。
      広告出稿にあたって駅看板の様に取付撤去費が発生しないので出稿にあたり非常に費用対効果が高い媒体とも言えます。"
      pagepath={location.pathname}
      pageimg={data.posterimage.childImageSharp.original.src}
      pageimgw={data.posterimage.childImageSharp.original.width}
      pageimgh={data.posterimage.childImageSharp.original.height}
    />
    <section className="area">
    <div className="container">
      <h2 className="bar">ポスター</h2>
      <br />
      <figure>
        <GatsbyImage image={data.posterimage.childImageSharp.gatsbyImageData} alt="" />
      </figure>
      <br />
      <p className="article">
      <span className="article-sub">駅ポスターについて</span><br />
      １枚７日間の単位から広告展開出来る、紙媒体の広告です。通路壁面などにアクリルの枠内に掲出します。
基本はＢ１縦サイズですが、Ｂ１サイズ枠を横並びに２つ使ってＢ０横サイズの展開も可能です。
広告出稿にあたって駅看板の様に取付撤去費が発生しないので出稿にあたり非常に費用対効果が高い媒体とも言えます。
駅ごとにランク設定があり料金が異なっていますので、ターゲットエリアにより掲出駅を選択して、少ない予算でより多くピンポイントに広告接触を狙える媒体です。<br /><br />
パノラマポスター広告やロングサイズポスター広告などの大型ポスターの場合は、B0ポスターを10枚以上使って掲出する商品で
各1枚１枚を異なるデザインでもいいですし、各１枚１枚のポスター各パーツとなり大きな一つのデザインを作り上げる様なデザイン展開でも可能ですので
見せ方により展開を選択頂ければと思います。<br /><br />
また、ご希望の駅の特定のエリアのみに掲出されるのではなく、駅全体に訴求したいという場合には、新宿南口セットポスター広告や池袋セットポスター広告の様な
広範囲に掲出箇所が設定してあるセットポスター広告がおすすめです。
あとは、相互乗り入れしているりんかい線のポスター広告や秋葉原に発着するつくばエクスプレスポスター広告もありますので、合わせてご活用ください
『自然と目に入る』というメディアイメージになっており、男女合計して５６％まで達しています。
また、週に１回以上の接触割合は男性で約５４％、女性で４５％になっています。<br />
出典：Jeki首都圏移動者調査<br /><br />
広告接触率・広告到達率・商品興味関心度の項目で
『見た（見たような気がする）』『（非常に）興味を持った』と言う人の割合約７０％までに達していて、媒体商品の中でトップの数値を叩き出しています。<br />
出典：交通広告共通指標策定調査<br /><br />
広告掲出開始の１週間前にデザイン審査が完了した広告物を納品出来ればいいので、広告したい対象駅と希望日程を準備いただいてご相談頂ければと思います。
      </p> 
    </div>
    </section>
    <section className="Advertising">
    <div className="container">
    <div className="block">
        <div className="ad-detail">
        <Link to={`/cat/poster_cat/`}>
        <button className="button-first">ポスター広告一覧へ</button>
        </Link>
        </div>
        <div className="ad-detail">
        <Link to={`/form/`}>
        <button className="button-second">お問い合わせ</button>
        </Link>
        </div>
    </div>
    </div>
    </section>
  </Layout>
)
  
export const query = graphql`
  query {
    posterimage: file(relativePath: {eq: "posterimage.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
        original {
          src
          height
          width
        }
      }
    }
  }
`

export default Poster